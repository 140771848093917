<template>
  <v-dialog :value="value" max-width="1000" scrollable persistent @input="$emit('input', $event)">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <ValidationObserver ref="formObserver">
          <v-form :disabled="isLoading">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <vee-text-field v-model="item.id" label="Id" disabled />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-text-field
                    v-model="item.uniqueKey"
                    rules="required|max:128"
                    label="Unique key*"
                  />
                </v-col>

                <v-col cols="12">
                  <vee-text-field
                    v-model="item.titleOfSession"
                    rules="required|max:128"
                    label="Title of session*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.sessionType"
                    :rules="'required|oneOf:' + Object.values(SessionType).join(',')"
                    :items="[''].concat(Object.values(SessionType))"
                    label="Session type*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.category"
                    :rules="'required|oneOf:' + Object.values(AssessmentCategory).join(',')"
                    :items="[''].concat(Object.values(AssessmentCategory))"
                    label="Category*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.level"
                    :rules="'required|oneOf:' + Object.values(AssessmentLevel).join(',')"
                    :items="[''].concat(Object.values(AssessmentLevel))"
                    label="Level*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.frequency"
                    :rules="'required|oneOf:' + Object.values(AssessmentFrequency).join(',')"
                    :items="[''].concat(Object.values(AssessmentFrequency))"
                    label="Frequency*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.measurement"
                    :rules="'required|oneOf:' + Object.values(AssessmentMeasurement).join(',')"
                    :items="[''].concat(Object.values(AssessmentMeasurement))"
                    label="Measurement*"
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <vee-select
                    v-model="item.practiceType"
                    :rules="'required|oneOf:' + Object.values(AssessmentPracticeType).join(',')"
                    :items="[''].concat(Object.values(AssessmentPracticeType))"
                    label="Practical type*"
                  />
                </v-col>

                <v-col cols="12">
                  <vee-text-field
                    v-model="item.goal"
                    rules="required"
                    label="Goal*"
                  />
                </v-col>

                <v-col cols="6" sm="3">
                  <vee-text-field
                    v-model="item.sortNo"
                    rules="required|integer"
                    label="Sort no.*"
                    type="number"
                    min="0"
                  />
                </v-col>

                <v-col cols="6" sm="3">
                  <vee-text-field
                    v-model="item.exercisePriority"
                    rules="required|integer"
                    label="Exercise priority*"
                    type="number"
                    min="0"
                  />
                </v-col>

                <v-col cols="12">
                  <h3>Tips</h3>
                </v-col>

                <v-col cols="12">
                  <v-slide-y-transition group>
                    <v-card
                      v-for="(tip, tipIndex) in item.tips"
                      :key="`tip-${tipIndex}`"
                      class="mb-5"
                      outlined
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <div class="d-flex justify-space-between">
                              <v-card-title class="pa-0 text-h6">
                                Tip {{ tipIndex + 1 }}/{{ item.tips.length }}
                              </v-card-title>

                              <v-btn icon small class="float-right" @click="onRemoveTip(tipIndex)">
                                <v-icon size="20">
                                  {{ icons.mdiDeleteOutline }}
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-text-field v-model="tip.title" label="Title" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-text-field v-model="tip.subtitle" label="Subtitle" />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <upload-file
                              accept="image/*"
                              rules="imageFile"
                              label="Image url"
                              @uploadSuccess="tip.imageUrl = $event.url"
                            />
                            <vee-text-field
                              :value="tip.imageUrl"
                              :vid="`tip-${tipIndex}`"
                              rules="url"
                              clearable
                              dense
                              filled
                              @input="tip.imageUrl = $event ? $event : ''"
                            />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <vee-select
                              v-model="tip.type"
                              :vid="`tip-${tipIndex}`"
                              :rules="'required|oneOf:' + Object.values(TipType).join(',')"
                              :items="[''].concat(Object.values(TipType))"
                              label="Type*"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-slide-y-transition>

                  <v-btn color="primary" class="mb-4" outlined @click="onAddTip">
                    Add tip
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <v-slide-y-transition group>
                    <v-card
                      v-for="(scoringDescription, scoringDescriptionIndex) in item.scoringDescriptions"
                      :key="`scoringDescription-${scoringDescriptionIndex}`"
                      class="mb-5"
                      outlined
                    >
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <div class="d-flex justify-space-between">
                              <v-card-title class="pa-0 text-h6">
                                Scoring description {{ scoringDescriptionIndex + 1 }}/{{ item.scoringDescriptions.length }}
                              </v-card-title>

                              <v-btn icon small class="float-right" @click="onRemoveScoringDescription(scoringDescriptionIndex)">
                                <v-icon size="20">
                                  {{ icons.mdiDeleteOutline }}
                                </v-icon>
                              </v-btn>
                            </div>
                          </v-col>

                          <v-col cols="12">
                            <vee-text-field
                              v-model="item.scoringDescriptions[scoringDescriptionIndex]"
                              rules="required"
                              label="Description*"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-slide-y-transition>

                  <v-btn color="primary" class="mb-4" outlined @click="onAddScoringDescription">
                    Add scoring description
                  </v-btn>
                </v-col>

                <v-col cols="12">
                  <vee-autocomplete
                    v-model="item.exercise"
                    :loading="areExercisesLoading"
                    :items="exercises"
                    rules="required"
                    label="Exercise*"
                    item-text="name"
                    item-value="uniqueKey"
                    return-object
                    clearable
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        close
                        small
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        @click="data.select"
                        @click:close="removeExercise(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.id }} /
                          {{ data.item.uniqueKey }} /
                          <v-chip small>
                            {{ data.item.category }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </vee-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </ValidationObserver>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="onCancel">
          Cancel
        </v-btn>
        <v-btn text :loading="isLoading" @click="onSave">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDeleteOutline } from '@mdi/js'
import { onMounted, computed, ref } from 'vue'
import { ValidationObserver } from 'vee-validate'
import useExercises from '@/composables/api/exercises'
import {
  AssessmentCategory, AssessmentFrequency, AssessmentLevel,
  AssessmentMeasurement, AssessmentPracticeType, SessionType, TipType,
} from '@/enums/enums'
import UploadFile from '@/layouts/components/UploadFile.vue'

export default {
  components: {
    UploadFile,
    ValidationObserver,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    item: {
      required: true,
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { exercises, isLoading: areExercisesLoading, fetchExercises } = useExercises()

    const formObserver = ref(null)

    const formTitle = computed(() => (
      props.item.id === '' ? 'Create a fitness assessment' : 'Edit the fitness assessment'
    ))

    onMounted(async () => {
      await fetchExercises('fitness')
    })

    const onAddTip = () => {
      props.item.tips.push({
        imageUrl: '',
        title: '',
        subtitle: '',
        type: '',
      })
    }

    const onRemoveTip = tipIndex => {
      props.item.tips.splice(tipIndex, 1)
    }

    const onAddScoringDescription = () => {
      props.item.scoringDescriptions.push('')
    }

    const onRemoveScoringDescription = scoringDescriptionIndex => {
      props.item.scoringDescriptions.splice(scoringDescriptionIndex, 1)
    }

    const onCancel = () => {
      emit('cancel')
    }

    const onSave = async () => {
      const isValid = await formObserver.value.validate()

      if (isValid) {
        emit('save', props.item)
      }
    }

    const removeExercise = () => {
      // eslint-disable-next-line no-param-reassign
      props.item.exercise = null
    }

    return {
      formObserver,
      formTitle,

      areExercisesLoading,
      exercises,

      onAddTip,
      onRemoveTip,

      onAddScoringDescription,
      onRemoveScoringDescription,

      onCancel,
      onSave,

      removeExercise,

      AssessmentCategory,
      AssessmentFrequency,
      AssessmentLevel,
      AssessmentMeasurement,
      AssessmentPracticeType,
      SessionType,
      TipType,

      icons: {
        mdiDeleteOutline,
      },
    }
  },
}
</script>
